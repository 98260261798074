import type { VariantColorsResolver } from "@mantine/core";
import {
  createTheme,
  defaultVariantColorsResolver,
  em,
  parseThemeColor,
  rem,
} from "@mantine/core";
import { Inter } from "next/font/google";

const variantColorResolver: VariantColorsResolver = (input) => {
  const defaultResolvedColors = defaultVariantColorsResolver(input);
  const parsedColor = parseThemeColor({
    color: input.color || input.theme.primaryColor,
    theme: input.theme,
  });

  if (input.variant === "filled") {
    return {
      background: parsedColor.value,
      hover: "white",
      hoverColor: parsedColor.value,
      border: `${rem(2)} solid ${parsedColor.value}`,
      color: "white",
    };
  }

  return defaultResolvedColors;
};

export const theme = createTheme({
  variantColorResolver,
  headings: {
    fontWeight: "700",
    fontFamily: "var(--font-inter), var(--font-noto-sans-sc)",
    sizes: {
      h1: {
        fontSize: rem(48),
        fontWeight: "700",
        lineHeight: rem(60),
      },
      h2: {
        fontSize: rem(34),
        fontWeight: "700",
        lineHeight: rem(50),
      },
      h3: {
        fontSize: rem(28),
        fontWeight: "700",
        lineHeight: rem(38),
      },
      h4: {
        fontSize: rem(20),
        fontWeight: "400",
        lineHeight: rem(28),
      },
      h5: {
        fontSize: rem(18),
        fontWeight: "400",
        lineHeight: rem(26),
      },
      h6: {
        fontSize: rem(16),
        fontWeight: "400",
        lineHeight: rem(24),
      },
    },
  },
  components: {
    Anchor: {
      defaultProps: {
        c: "#007CEE",
        className: "hover:underline underline-offset-4 decoration-1",
      },
    },
    Text: {
      defaultProps: {
        c: "#555",
      },
    },
    List: {
      defaultProps: {
        classNames: {
          itemWrapper: "md:w-full w-[95%]",
        },
      },
    },
  },
  fontSizes: {
    xs: rem(12),
    sm: rem(14),
    md: rem(16),
    lg: rem(18),
    xl: rem(20),
  },
  breakpoints: {
    xs: em(480),
    sm: em(768),
    md: em(1024),
    lg: em(1184),
    xl: em(1440),
  },
  primaryShade: 9,
  colors: {
    blue: [
      "#E7F5FF",
      "#E9ECF1",
      "#A5D8FF",
      "#BFC8D5",
      "#4DABF7",
      "#95A4BA",
      "#228BE6",
      "#6C81A0",
      "#3D5880",
      "#264572",
    ],
    orange: [
      "#FFF4E6",
      "#FDEFE5",
      "#FFD8A8",
      "#F9D0B3",
      "#FFA94D",
      "#F5B281",
      "#FD7E14",
      "#F2944F",
      "#E8590C",
      "#EC6503",
    ],
    dark: [
      "#EEEEEE",
      "#FAFAFA",
      "#EEEEEE",
      "#FFFAF6",
      "#F2F4F6",
      "#DDDDDD",
      "#AAAAAA",
      "#777777",
      "#555555",
      "#333333",
    ],
  },
});
